import { AxiosError } from 'axios';
import isServerError from './isServerError';
import isTooManyRequestError from './isToManyRequestError';
import isBadRequestError from './isBadRequestErrort';
import isClientError from './isClientError';
import isNotAllowedError from './isNotAllowedError';
import isNotFoundError from './isNotFoundError';
import isRequestEntityTooLargeError from './isRequestEntityTooLargeError';
import isValidationError from './isValidationError';

export function handleError(error: AxiosError, callback?: Function): string {
  let message = '問題が発生しました';

  if (!navigator.onLine) {
    message = '通信状態を確認してください'
  } else if (isBadRequestError(error)) {
    message = '不正なリクエストです';
  } else if (isNotAllowedError(error)) {
    message = '権限がありません';
  } else if (isNotFoundError(error)) {
    message = 'リソースが見つかりません';
  } else if (isRequestEntityTooLargeError(error)) {
    message = 'データサイズが大きすぎます';
  } else if (isValidationError(error)) {
    message = '入力内容に問題があります';
  } else if (isTooManyRequestError(error)) {
    message = 'しばらく時間をおいてからお試しください';
  } else if (isClientError(error)) {
    message = 'リクエストに問題があります';
  } else if (isServerError(error)) {
    message = 'サーバー上で問題が発生しました';
  }

  return message;
}
