import React from 'react';
import LayoutConfig from '../../layout/LayoutConfig';

interface Props {}

const PrivacyPage: React.FC<Props> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LayoutConfig title='プライバシーポリシー' />
      <p>
      このプライバシーポリシー（以下、「本ポリシー」といいます）は「グラブル救援検索」（以下、「当サービス」といいます）の個人情報の取り扱い方針を定めるものです。当サービスの利用者（以下、「ユーザー」といいます）は本ポリシーに同意したものとみなされます。
      </p>
      <h2>Cookie（クッキー）の利用</h2>
      <p>
        当サービスでは、より良いユーザー体験を提供するため、Cookieを利用しています。<br/>
        Cookieとは、サイトにアクセスした際にブラウザに保存される情報ですが、お名前やメールアドレス等の個人情報は含まれません。<br/>
        <br/>
        当サービスでは、以下の目的でCookieを利用します。<br/>
      </p>

      <h3>アクセス解析</h3>
      <p>
        当サービスは、サイトの利用状況を把握するためにGoogleが提供する Google Analytics を利用しています。<br/>
        そのため、当サービスのページ全般において Google Analytics から提供されるCookieが使用されています。これらのCookieによって個人が特定される情報が収集されることはありません。<br/>
        この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
      </p>

      <h3>第三者配信の広告サービス</h3>
      <p>
        当サービスでは、第三者配信事業者のサーバーからCookie等を使用したターゲティング広告が配信される場合があります。これにより取得された情報は第三者企業のサーバーに記録され、当該企業のプライバシーポリシーのもとで管理されます。
      </p>
    </>
  );
}

export default PrivacyPage;