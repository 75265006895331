import { Epic, combineEpics } from 'redux-observable';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import * as appActions from '../actions/AppAction';
import * as infoActions from '../actions/InformationAction';
import request from '../utils/function/request';
import { of } from 'rxjs';
import { Information } from '../utils/interface/Information';

/**
 * お知らせを取得
 * 
 * @param action$ 
 */
const fetchInformationsEpic: Epic = action$ => action$.pipe(
  filter(infoActions.fetchInformations.started.match),
  switchMap(({payload}) => request<Information[]>({
    url: '/v1/informations'
  }).pipe(
    map(res => infoActions.fetchInformations.done({params: payload, result: res.data})),
    catchError(error => of(
      appActions.handleError(error),
      infoActions.fetchInformations.failed({error})
    ))
  ))
);

const informationEpic = combineEpics(
  fetchInformationsEpic,
);

export default informationEpic;