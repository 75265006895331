import actionCreatorFactory from 'typescript-fsa';
import { RaidActionType as ActionType  } from '../actionTypes/RaidActionType';
import { Tweet } from '../utils/interface/Tweet';

const actionCreator = actionCreatorFactory('Raid');

// マルチバトルリストを取得
export const fetchRaids = actionCreator.async<void, number[]>(ActionType.FETCH_RAIDS);

// 救援ツイートを取得
export const fetchRaidTweets = actionCreator.async<string, Tweet[]>(ActionType.FETCH_RAID_TWEETS);