declare const window: any;

export function base64ToBlob(dataURI: string, fileType: string): Blob {
  const binary = window.atob(dataURI.split(',')[1]);
  const buffer = new Uint8Array(binary.length)

  for (var i = 0; i < binary.length; i++) {
    buffer[i] = binary.charCodeAt(i);
  }

  return new Blob([buffer.buffer], {type: fileType});
}