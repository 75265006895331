import React from 'react';
import Header from './Header';
import Menu from './Menu';
import Container from '../common/Container';
import { makeStyles } from '@material-ui/core';
import Toasts from '../common/Toasts';
import { useSelector, shallowEqual } from 'react-redux';
import { ReduxState } from '../../reducers';

const useStyles = makeStyles({
  main: {
    paddingBottom: 80
  }
});

interface Props {}

const AppShell: React.FC<Props> = (props) => {
  const classes = useStyles();

  const toasts = useSelector((state: ReduxState) => state.toast, shallowEqual);

  return (
    <>
      <Header />
      <Menu />
      <main className={ classes.main }>
        <Container withGutter>
          { props.children }
        </Container>
      </main>
      <Toasts toasts={ toasts } />
    </>
  );
}

export default AppShell;