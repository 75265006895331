import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import theme from '../../configs/material-ui.config';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  offset: theme.mixins.toolbar,
});

interface Props {}

const Progress: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={ classes.root }>
        <div className={ classes.offset }/>
        <CircularProgress/>
      </div>
    </>
  );
}

export default Progress;