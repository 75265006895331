import React from 'react';
import LayoutConfig from '../../layout/LayoutConfig';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';
import * as infoActions from '../../../actions/InformationAction';
import InformationCard from '../../common/InformationCard';
import EmptyMessage from '../../common/EmptyMessage';

const useStyles = makeStyles({
  section: {
    marginBottom: 30
  },
  pwa: {
    textAlign: 'center',
    padding: '18px 10px',
    marginTop: 20,
    marginBottom: 20,
    boxSizing: 'border-box',
    border: '1px solid #F4D980',
    backgroundColor: '#F8E7AE',
    borderRadius: 5
  },
  progress: {
    padding: '10px 0',
    textAlign: 'center'
  },
  infos: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      borderBottom: '1px solid #d0d0d0'
    },
    '& li:first-child': {
      borderTop: '1px solid #d0d0d0'
    }
  },
  sec: {
    '& p': {
      fontSize: 16
    },
    '& + &': {
      marginTop: 25
    }
  }
})

interface Props {}

const TopPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles()

  const {isLoading, informations} = useSelector((state: ReduxState) => ({
    isLoading: state.information.isLoading,
    informations: state.information.informations
  }), shallowEqual);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    // お知らせを取得
    dispatch(infoActions.fetchInformations.started());
  }, []);

  return (
    <>
      <LayoutConfig />
      <section className={ classes.section }>
        <h2>お知らせ</h2>
        {isLoading
          ? (
            <div className={ classes.progress }>
              <CircularProgress size={ 30 }/>
            </div>
          )
          : (informations.length > 0
            ? (
              <ul className={ classes.infos }>
                {informations.map(info => (
                  <li key={ info.id }>
                    <InformationCard information={ info } />
                  </li>
                ))}
              </ul>
            )
            : (<EmptyMessage>お知らせはありません</EmptyMessage>)
          )
        }
      </section>
      <section className={ `${classes.sec} ${classes.section}` }>
        <h2>使い方</h2>
        <div className={ classes.sec }>
          <p>1. メニューから検索したいマルチバトルを選択する。</p>
          <img src="/assets/img/howto1.jpg" width="100%" alt=""/>
        </div>
        <div className={ classes.sec }>
          <p>2. 救援ツイートをクリックして参戦IDをコピーする。</p>
          <img src="/assets/img/howto2.jpg" width="100%" alt="" style={{ marginBottom: 10 }}/>
          <img src="/assets/img/howto3.jpg" width="100%" alt=""/>
        </div>
      </section>
    </>
  );
}

export default TopPage;