import { ReduxState } from '../reducers';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import schemas from '../schemas';
import { Raid } from '../utils/interface/Raid';

const raidEntitiesSelector = (state: ReduxState) => state.entity.raids;
const raidIdSelector = (state: ReduxState, raidId: string) => parseInt(raidId, 10);

const raidSelector = createSelector(
  [raidEntitiesSelector, raidIdSelector],
  (raids, id): Raid | null => raids[id] ? denormalize(id, schemas.raid, {raids}) : null
);

export default raidSelector;