import actionCreatorFactory from 'typescript-fsa';
import { LayoutActionType as ActionType  } from '../actionTypes/LayoutActionType';

const actionCreator = actionCreatorFactory('Layout');

// メニューを開く
export const openMenu = actionCreator(ActionType.MENU_OPENED);

// メニューを閉じる
export const closeMenu = actionCreator(ActionType.MENU_CLOSED);

// タイトルを設定
export const setTitle = actionCreator<string | undefined>(ActionType.TITLE_SET);

// 
export const setRightControl = actionCreator<JSX.Element | undefined>(ActionType.RIGHT_CONTROL_SET);