import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as raidActions from '../actions/RaidAction';
import { Tweet } from '../utils/interface/Tweet';

export interface RaidState {
  list: number[];
  tweet: {
    isLoading: boolean;
    tweets: Tweet[]
  };
}

const initialState: RaidState = {
  list: [],
  tweet: {
    isLoading: false,
    tweets: []
  }
};

const raidReducer = reducerWithInitialState(initialState)

// マルチバトルリストの取得 - 完了
.case(raidActions.fetchRaids.done, (state, payload) => ({
  ...state,
  list: payload.result
}))

// 救援ツイートの取得 - 開始
.case(raidActions.fetchRaidTweets.started, (state, payload) => ({
  ...state,
  tweet: {
    ...state.tweet,
    isLoading: true,
  }
}))

// 救援ツイートの取得 - 成功
.case(raidActions.fetchRaidTweets.done, (state, payload) => ({
  ...state,
  tweet: {
    isLoading: false,
    tweets: payload.result
  }
}))

// 救援ツイートの取得 - 失敗
.case(raidActions.fetchRaidTweets.failed, (state, payload) => ({
  ...state,
  tweet: {
    ...state.tweet,
    isLoading: false,
  }
}))

export default raidReducer;