import React from 'react';
import styles  from './Toasts.module.scss';
import Toast from './Toast';
import { Toast as IToast } from '../../utils/interface/Toast';
import { Fade } from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';

interface ToastsProps {
  toasts: IToast[];
}

const Toasts: React.FC<ToastsProps> = (props) => {
  return (
    <>
      <TransitionGroup component='ul' className={ styles.root }>
        {props.toasts.map(toast => (
          <Fade key={ toast.id }>
            <li className={ styles.item }>
              <Toast toast={ toast } />
            </li>
          </Fade>
        ))}
      </TransitionGroup>
    </>
  );
}

export default Toasts;
