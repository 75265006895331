import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, makeStyles, List, ListItem, Divider, Link as MuiLink, Toolbar, Typography } from '@material-ui/core';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ReduxState } from '../../reducers';
import * as layoutActions from '../../actions/LayoutAction';
import RaidListItem from '../common/RaidListItem';
import raidCategoriesSelector from '../../selectors/raidCategoriesSelector';
import theme from '../../configs/material-ui.config';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles({
  listItem: {
    color: 'inherit',
    textDecoration: 'none!important'
  },
  inner: {
    width: 250,
    paddingBottom: 80
  },
  toolbar: {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main
  },
  icon: {
    fontSize: '1em',
    marginLeft: '0.5em'
  }
});

interface Props {}

const Menu: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {open, categories} = useSelector((state: ReduxState) => ({
    open: state.layout.menuIsActive,
    categories: raidCategoriesSelector(state),
  }), shallowEqual);

  const handleClose = () => {
    dispatch(layoutActions.closeMenu());
  }

  return (
    <>
      <Drawer
        anchor='left'
        open={ open }
        onClose={ handleClose }
      >
        <Toolbar className={ classes.toolbar }>
          <Typography variant='h6'>MENU</Typography>
        </Toolbar>
        <div className={ classes.inner }>
          <List onClick={ handleClose }>
            <ListItem button className={ classes.listItem } component={ Link } to='/'>トップページ</ListItem>
          </List>
          <Divider />
          <List>
            {categories.map(category => (
              <RaidListItem key={ category.name } category={ category } />
            ))}
          </List>
          <Divider />
          <List onClick={ handleClose }>
            <ListItem button className={ classes.listItem } component={ Link } to='/privacy'>プライバシーポリシー</ListItem>
            <ListItem button className={ classes.listItem } component={ Link } to='/feedback'>フィードバック</ListItem>
            <ListItem button className={ classes.listItem } component={ MuiLink } href='https://lit-gallery.com' target='_blank'>
              開発<OpenInNewIcon className={ classes.icon } />
            </ListItem>
          </List>
          <Divider />
        </div>
      </Drawer>
    </>
  );
}

export default Menu;