import { schema } from 'normalizr';

const raid = new schema.Entity('raids');

const raidCategory = new schema.Entity('raidCategories', {
  raids: [raid]
});

const schemas = {
  raid,
  raidCategory
};

export default schemas;