import React from 'react';
import { RouteComponentProps } from 'react-router';
import { List, ListItem, makeStyles, IconButton } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as raidActions from '../../../actions/RaidAction';
import { ReduxState } from '../../../reducers';
import Tweet from '../../common/Tweet';
import RefreshIcon from '@material-ui/icons/Refresh';
import raidSelector from '../../../selectors/raidSelector';
import LayoutConfig from '../../layout/LayoutConfig';
import Progress from '../../common/Progress';
import * as toastActions from '../../../actions/ToastAction';
import { copyToClipboard } from '../../../utils/function/copyToClipboard';
import EmptyMessage from '../../common/EmptyMessage';
import moment from 'moment';

const useStyles = makeStyles({
  list: {
    marginLeft: -16,
    marginRight: -16,
  },
  listItem: {
    padding: 16
  },
  reloadBtn: {
    color: '#ffffff'
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 0'
  }
});

interface Props extends RouteComponentProps<{id: string}> {}

const RaidPage: React.FC<Props> = (props) => {
  const raidId = props.match.params.id;
  const classes = useStyles();
  const dispatch = useDispatch();
  const {isLoading, raid} = useSelector((state: ReduxState) => ({
    isLoading: state.raid.tweet.isLoading,
    raid: raidSelector(state, raidId)
  }), shallowEqual)

  const tweets = useSelector((state: ReduxState) => state.raid.tweet.tweets, shallowEqual);

  const loadTweets = () => {
    dispatch(raidActions.fetchRaidTweets.started(raidId));
  }

  const handleSelect = (battleId: string) => {
    copyToClipboard(battleId);
    dispatch(toastActions.show({message: `参戦ID ${battleId} をコピーしました`, type: 'info'}));
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    loadTweets();
  }, [raidId]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [tweets]);

  return (
    <>
      <LayoutConfig
        title={ raid ? raid.name : '' }
        rightControl={(
          <IconButton edge='end' className={ classes.reloadBtn } onClick={ loadTweets }>
            <RefreshIcon />
          </IconButton>
        )}
      />

      {tweets.length > 0 && (
        <List className={ classes.list } disablePadding>
          {tweets.map(tweet => (
            <ListItem
              key={ `${tweet.battle_id}_${moment(tweet.time).unix()}` } 
              divider
              button
              className={ classes.listItem }
              onClick={() => handleSelect(tweet.battle_id)}
            >
              <Tweet tweet={ tweet } />
            </ListItem>
          ))}
        </List>
      )}

      {(tweets.length === 0 && !isLoading) && (
        <div className={ classes.empty }>
          <EmptyMessage>{`${raid ? `${raid.name} の` : ''}救援はありません`}</EmptyMessage>
        </div>
      )}
      
      {isLoading && (<Progress />)}
    </>
  );
}

export default RaidPage;