import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import layoutReducer, { LayoutState } from './layoutReducer';
import raidReducer, { RaidState } from './raidReducer';
import toastReducer, { ToastState } from './toastReducer';
import entityReducer, { EntityState } from './entityReducer';
import informationReducer, { InformationState } from './informationReducer';

export interface ReduxState {
  entity: EntityState;
  layout: LayoutState;
  raid: RaidState;
  information: InformationState;
  toast: ToastState;
  router: RouterState;
}

const reducer = (history: History) => combineReducers<ReduxState, any>({
  entity: entityReducer,
  layout: layoutReducer,
  raid: raidReducer,
  information: informationReducer,
  toast: toastReducer,
  router: connectRouter(history),
});

export default reducer;