import React from 'react';
import { useDispatch } from 'react-redux';
import * as layoutActions from '../../actions/LayoutAction';

interface Props {
  title?: string;
  rightControl?: JSX.Element
}

const LayoutConfig: React.FC<Props> = ({title, rightControl}) => {
  const dispatch = useDispatch();

  // タイトルを設定
  React.useEffect(() => {
    dispatch(layoutActions.setTitle(title));
  }, [title]);

  // RightControlを設定
  React.useEffect(() => {
    dispatch(layoutActions.setRightControl(rightControl));
  }, [rightControl]);

  return (
    <></>
  );
}

export default LayoutConfig;