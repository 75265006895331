import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import store from './configureStore';
import './index.scss';
import App from './App';
import theme from './configs/material-ui.config';
import { ConnectedRouter } from 'connected-react-router';
import history from './history';
import * as serviceWorker from './serviceWorker';
import swConfig from './configs/sw.config';

declare const window: any;

if (process.env.NODE_ENV === "development") {
  window['ga-disable-GA_MEASUREMENT_ID'] = true;
}

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={ theme }>
      <Provider store={ store }>
        <ConnectedRouter history={ history }>
          <App />
        </ConnectedRouter>
      </Provider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.register(swConfig);
