import { makeStyles } from '@material-ui/core';
import React from 'react';

interface Props {}

const EmptyMessage: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <p className={ classes.root }>{ props.children }</p>
    </>
  );
}

const useStyles = makeStyles({
  root: {}
})

export default EmptyMessage;