import moment from 'moment';

export function timestampPipe(timestamp: string): string {
  const createdAt = moment(timestamp);

  if (!createdAt.isValid()) {
    return timestamp;
  }

  const diff = moment().diff(createdAt, 'seconds');

  if (diff >= 86400) {
    return `${Math.floor( diff / 86400 )}日前`;
  } else if (diff >= 3600) {
    return `${Math.floor( diff / 3600 )}時間前`;
  } else if (diff >= 60) {
    return `${Math.floor( diff / 60 )}分前`;
  } else {
    return `${Math.floor( diff )}秒前`;
  }
}
