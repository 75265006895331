import { ReduxState } from '../reducers';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import schemas from '../schemas';
import { RaidCategory } from '../utils/interface/RaidCategory';

const raidEntitiesSelector = (state: ReduxState) => state.entity.raids;
const raidCategoryEntitiesSelector = (state: ReduxState) => state.entity.raidCategories;
const raidCategoryIds = (state: ReduxState) => state.raid.list;

const raidCategoriesSelector = createSelector(
  [raidEntitiesSelector, raidCategoryEntitiesSelector, raidCategoryIds],
  (raids, raidCategories, ids): RaidCategory[] => denormalize(ids, [schemas.raidCategory], {raids, raidCategories})
);

export default raidCategoriesSelector;