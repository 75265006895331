import React from 'react';
import { Route } from 'react-router';
import TopPage from './components/page/top/TopPage';
import RaidPage from './components/page/raid/RaidPage';
import FeedbackPage from './components/page/feedback/FeedbackPage';
import PrivacyPage from './components/page/privacy/PrivacyPage';

const routes: JSX.Element[] = [
  // トップページ
  <Route exact key='/' path='/' component={ TopPage } />,

  // マルチバトル
  <Route exact key='/raid/:id' path='/raid/:id' component={ RaidPage } />,

  // プライバシーポリシー
  <Route exact key='/privacy' path='/privacy' component={ PrivacyPage } />,

  // フィードバック
  <Route exact key='/feedback' path='/feedback' component={ FeedbackPage } />,
];

export default routes;