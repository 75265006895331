import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { config } from '../configs/app.config';
import * as layoutActions from '../actions/LayoutAction';

export interface LayoutState {
  title: string;
  menuIsActive: boolean;
  rightControl?: JSX.Element;
}

const initialState: LayoutState = {
  title: config.title,
  menuIsActive: false,
};

const layoutReducer = reducerWithInitialState(initialState)

// メニューを開く
.case(layoutActions.openMenu, state => ({...state, menuIsActive: true}))

// メニューを閉じる
.case(layoutActions.closeMenu, state => ({...state, menuIsActive: false}))

// タイトルを設定
.case(layoutActions.setTitle, (state, payload) => {
  document.title = (payload ? `${payload}｜` : '') + config.title;

  return {
    ...state,
    title: payload || config.title
  }
})

// RightControlを設定
.case(layoutActions.setRightControl, (state, payload) => ({
  ...state,
  rightControl: payload
}))

export default layoutReducer;