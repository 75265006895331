import React from 'react';
import { CssBaseline, makeStyles } from '@material-ui/core';
import AppShell from './components/layout/AppShell';
import { Switch, withRouter, RouteComponentProps } from 'react-router';
import routes from './routes';
import { useDispatch } from 'react-redux';
import * as raidActions from './actions/RaidAction';
import Ad from './components/common/Ad';

declare const window: any;

interface Props extends RouteComponentProps {}

const App: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const loadRaids = () => {
    dispatch(raidActions.fetchRaids.started());
  }

  React.useEffect(() => {
    window.gtagPageview(props.location.pathname);
  }, [props.location.pathname]);

  React.useEffect(() => {
    loadRaids();
  }, []);

  return (
    <>
      <CssBaseline />
      <AppShell>
        <Switch>{ routes }</Switch>
        <div className={ classes.adContainer }>
          <div className={ classes.ad }>
          <Ad
            style={{
              display: 'inline-block',
              width: 320,
              height: 50,
              verticalAlign: 'bottom'
            }}
            client={ process.env.REACT_APP_AD_CLIENT || '' }
            slot={ process.env.REACT_APP_AD_SLOT || '' }
            format=''
            fullWidthResponsive='false'
          />
          </div>
        </div>
      </AppShell>
    </>
  );
}

const useStyles = makeStyles({
  ad: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    minWidth: 0,
    textAlign: 'center'
  },
  adContainer: {
    height: 50
  }
})

export default withRouter(App);
