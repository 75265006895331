import React, { useEffect } from 'react';

declare const window: any;

interface Props {
  style?: React.CSSProperties;
  client?: string;
  slot?: string;
  format?: string;
  fullWidthResponsive?: string;
}

const Ad: React.FC<Props> = (props) => {
  useEffect(() => {
    try {
        if (process.env.NODE_ENV === 'production') {
          window.adsbygoogle = window.adsbygoogle || [];
          window.adsbygoogle.push({})
        }
      } catch(e) {
        console.error(e);
      }
  }, [])

  return (
    <>
      <ins className="adsbygoogle"
        style={ props.style }
        data-ad-client={ props.client }
        data-ad-slot={ props.slot }
        data-ad-format={ props.format }
        data-full-width-responsive={ props.fullWidthResponsive }
      ></ins>
    </>
  );
}

export default Ad;