import React from 'react';
import { AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';
import MenuButton from './MenuButton';
import { useSelector, shallowEqual } from 'react-redux';
import { ReduxState } from '../../reducers';
import theme from '../../configs/material-ui.config';

const useStyles = makeStyles({
  title: {
    color: '#ffffff',
    marginLeft: 10,
    flexGrow: 1
  },
  offset: theme.mixins.toolbar,
})

interface Props {}

const Header: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {title, rightControl} = useSelector((state: ReduxState) => ({
    title: state.layout.title,
    rightControl: state.layout.rightControl
  }), shallowEqual);

  return (
    <>
      <AppBar>
        <Toolbar>
          <MenuButton edge='start' />
          <Typography className={ classes.title }
            component='h1' 
            variant='h6'
          >{ title }</Typography>
          {rightControl && (
            <div>{ rightControl }</div>
          )}
        </Toolbar>
      </AppBar>
      <div className={ classes.offset } />
    </>
  );
}

export default Header;