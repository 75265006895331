import React from 'react';
import { RaidCategory } from '../../utils/interface/RaidCategory';
import { ListItem, Collapse, List, makeStyles } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as layoutActions from '../../actions/LayoutAction';

const useStyles = makeStyles({
  listItem: {
    color: 'inherit',
    textDecoration: 'none!important',
    paddingLeft: 40,
  },
  inner: {
    flex: '1 1 auto',
    minWidth: 0,
  }
});

interface Props {
  category: RaidCategory;
}

const RaidListItem: React.FC<Props> = ({category}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(false);

  const toggle = () => {
    setOpen(!open);
  }

  const handleDrawerClose = () => {
    dispatch(layoutActions.closeMenu());
  }

  return (
    <>
      <ListItem button onClick={ toggle }>
        <div className={ classes.inner }>{ category.name }</div>
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItem>
      <Collapse in={ open }>
        <List onClick={ handleDrawerClose }>
          {category.raids.map(raid => (
            <ListItem
              key={ raid.id }
              button
              className={ classes.listItem }
              component={ Link }
              to={ `/raid/${raid.id}` }
            >
              <div>{ raid.name }</div>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default RaidListItem;