export const LayoutActionType = {
  // メニューを開く
  MENU_OPENED: 'MENU_OPENED',

  // メニューを閉じる
  MENU_CLOSED: 'MENU_CLOSED',

  // タイトルを設定
  TITLE_SET: 'TITLE_SET',

  RIGHT_CONTROL_SET: 'RIGHT_CONTROL_SET'
};