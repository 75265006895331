import React from 'react';
import { IconButton, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import * as toastActions from '../../actions/ToastAction';
import { Toast as IToast } from '../../utils/interface/Toast';
import { useDispatch } from 'react-redux';

interface Props {
  toast: IToast;
}

const Toast: React.FC<Props> = ({toast}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toastActions.hide(toast.id))
  }
  
  React.useEffect(() => {
    const timer = setTimeout(handleClose, 4000);

    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <>
      {toast.type === 'info'
        ? (
          <SnackbarContent
            message={ toast.message}
            action={(
              <IconButton size='small' aria-label='閉じる' color='inherit' onClick={ handleClose }>
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          />
        )
        : (
          <Alert elevation={6} variant='filled' severity={ toast.type }>
            { toast.message }
          </Alert>
        )
      }
      
    </>
  );
}

export default Toast;