import { from } from "rxjs";
import { take } from "rxjs/operators";

interface Config {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

const swConfig: Config = {
  onUpdate: (registration: ServiceWorkerRegistration) => {
    from(registration.unregister()).pipe(take(1))
    .subscribe(() => {
      window.location.href = window.location.href;
    })
  }
};

export default swConfig;
