import React from 'react';
import { Avatar, ListItemText, makeStyles, ListItemAvatar } from '@material-ui/core';
import { Tweet as ITweet } from '../../utils/interface/Tweet';
import { timestampPipe } from '../../utils/pipe/timestampPipe';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    minWidth: 0,
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 0
  },
  userId: {
    marginLeft: 5,
    color: '#969696'
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0
  },
  header: {
    display: 'flex',
    minWidth: 0,
    alignItems: 'center'
  },
  user: {
    marginRight: 'auto',
    flexShrink: 1,
    flexGrow: 0,
    flexBasis: 'auto',
    overflow: 'hidden'
  },
  userInner: {
    width: '100%',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  time: {
    flexShrink: 0,
    fontSize: 12,
    color: '#969696',
    paddingLeft: 10,
    boxSizing: 'border-box',
    lineHeight: 1
  },
});

interface Props {
  tweet: ITweet
}

const Tweet: React.FC<Props> = ({tweet}) => {
  const classes = useStyles();

  return (
    <>
      <div className={ classes.root }>
        <ListItemAvatar>
          <Avatar src={tweet.user_img} />
        </ListItemAvatar>
        <ListItemText
          className={ classes.listItemText }
          primary={
            <div className={ classes.header }>
              <div className={ classes.user }>
                <div className={ classes.userInner }>
                  {tweet.user_name}<span className={ classes.userId }>@{tweet.user_id}</span>
                </div>
              </div>
              <div className={ classes.time }>{ timestampPipe(tweet.time) }</div>
            </div>
          }
          secondary={tweet.text} 
        />
      </div>
    </>
  );
}

export default Tweet;