import { combineEpics } from 'redux-observable';
import appEpic from './appEpic';
import informationEpic from './informationEpic';
import raidEpic from './raidEpic';

const rootEpic = combineEpics(
  appEpic,
  raidEpic,
  informationEpic
);

export default rootEpic;