import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      50: '#e0f1ff',
      100: '#b3dbff',
      200: '#80c3ff',
      300: '#4dabff',
      400: '#2699ff',
      500: '#0087ff',
      600: '#007fff',
      700: '#0074ff',
      800: '#006aff',
      900: '#0057ff',
      A100: '#ffffff',
      A200: '#f2f6ff',
      A400: '#bfd1ff',
      A700: '#a6bfff',
    }
  },
});

export default theme;