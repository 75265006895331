import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 600,
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  withGutter: {
    paddingLeft: 16,
    paddingRight: 16
  }
});

interface Props {
  className?: string;
  withGutter?: boolean;
}

const Container: React.FC<Props> = ({className, withGutter, children}) => {
  const classes = useStyles();

  return (
    <>
      <div className={ `${classes.root} ${withGutter ? classes.withGutter : ''} ${className || ''}` }>
        {children}
      </div>
    </>
  );
}

export default Container;