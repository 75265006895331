import { Epic, combineEpics } from 'redux-observable';
import { filter, switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import * as appActions from '../actions/AppAction';
import * as raidActions from '../actions/RaidAction';
import * as entityActions from '../actions/EntityAction';
import request from '../utils/function/request';
import { RaidCategory } from '../utils/interface/RaidCategory';
import { of } from 'rxjs';
import { Tweet } from '../utils/interface/Tweet';
import { normalize } from 'normalizr';
import schemas from '../schemas';

/**
 * マルチバトルリストを取得
 * 
 * @param action$ 
 */
const fetchRaidsEpic: Epic = action$ => action$.pipe(
  filter(raidActions.fetchRaids.started.match),
  switchMap(() => request<RaidCategory[]>({
    url: '/v1/raids'
  }).pipe(
    mergeMap(res => {
      const normalizedData = normalize(res.data, [schemas.raidCategory]);
      return of(
        entityActions.update(normalizedData.entities),
        raidActions.fetchRaids.done({result: normalizedData.result})
      );
    }),
    catchError(error => of(
      appActions.handleError(error),
      raidActions.fetchRaids.failed({error})
    ))
  ))
);

/**
 * 救援ツイートを取得
 * 
 * @param action$ 
 */
const fetchRaidTweetsEpic: Epic = action$ => action$.pipe(
  filter(raidActions.fetchRaidTweets.started.match),
  switchMap(({payload}) => request<Tweet[]>({
    url: `/v1/raids/${payload}/tweets`
  }).pipe(
    map(res => raidActions.fetchRaidTweets.done({params: payload, result: res.data})),
    catchError(error => of(
      appActions.handleError(error),
      raidActions.fetchRaidTweets.failed({params: payload, error})
    ))
  ))
);

const raidEpic = combineEpics(
  fetchRaidsEpic,
  fetchRaidTweetsEpic
);

export default raidEpic;