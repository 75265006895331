import React from 'react';
import { makeStyles, TextField, Button, CircularProgress, FormHelperText } from '@material-ui/core';
import FormItem from '../../common/FormItem';
import request from '../../../utils/function/request';
import { finalize } from 'rxjs/operators';
import UploadFile from './UploadFiles';
import { ValidationError } from '../../../utils/interface/ValidationError';
import isValidationError from '../../../utils/function/isValidationError';
import { handleError } from '../../../utils/function/handleError';
import ErrorMessage from '../../common/ErrorMessage';
import SuccessMessage from '../../common/SuccessMessage';
import LayoutConfig from '../../layout/LayoutConfig';

export interface FeedbackFormValue {
  body: string;
  files?: File[];
}

const initialFormValue: FeedbackFormValue = {
  body: '',
};

const useStyles = makeStyles({
  message: {
    marginTop: 20,
    marginBottom: 30
  },
  fileSelect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ededed',
    borderRadius: 4,
    padding: '10px',
    cursor: 'pointer'
  },
  fileSelectLabel: {
    userSelect: 'none'
  },
  buttonContainer: {
    marginTop: 40
  },
  button: {
    height: 40,
    color: '#ffffff',
    backgroundColor: '#008bc4'
  },
  progress: {
    marginLeft: 10
  }
});

interface Props {}

const FeedbackPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isFileError, setIsFileError] = React.useState<boolean>(false);
  const [validationError, setValidationError] = React.useState<ValidationError>({});
  const [formValue, setFormValue] = React.useState<FeedbackFormValue>({...initialFormValue});

  // 内容を変更
  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value
    });
  }

  // ファイル選択
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFileError(false);
    const files = e.target.files;

    if (files) {
      let fileSize = 0;

      if (formValue.files) {
        for (let i = 0; i < formValue.files.length; i++) {
          fileSize += formValue.files[i].size;
        }
      }

      for (let i = 0; i < files.length; i++) {
        fileSize += files[i].size;
      }

      // 合計が25MBより大きい場合はエラーメッセージを表示
      if (Math.floor((fileSize / Math.pow(1000, 2)) * 100) / 100 > 25) {
        setIsFileError(true);
        return;
      }

      const newValue = formValue.files 
        ? formValue.files.concat(Array.from(files))
        : Array.from(files);

      setFormValue({
        ...formValue,
        files: newValue
      });
    }
  }

  // ファイルを削除
  const handleRemoveFile = (index: number) => {
    if (formValue.files) {
      const newValue = formValue.files.concat();
      newValue.splice(index, 1);
      setFormValue({
        ...formValue,
        files: newValue
      });
    }
  }

  // 送信
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSending(true);
    setIsSuccess(false);
    setErrorMessage(null);

    const formData = new FormData();
    formData.append('body', formValue.body);

    if (formValue.files) {
      formValue.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      })
    }

    request({
      method: 'post',
      headers: {'Content-Type': 'multipart/form-data'},
      url: '/v1/feedbacks',
      data: formData
    }).pipe(
      finalize(() => {
        setIsSending(false);
        window.scrollTo(0, 0);
      })
    ).subscribe(
      () => {
        setIsSuccess(true);
        setFormValue({...initialFormValue});
      },
      error => {
        if (error.response && isValidationError(error)) {
          setValidationError(error.response.data.errors);
        }
        setErrorMessage(handleError(error));
      }
    );
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const disabled = Array.from(formValue.body.trim()).length === 0;

  return (
    <>
      <LayoutConfig title='フィードバック' />

      {/* ErrorMessage */}
      {errorMessage && (
        <ErrorMessage>{ errorMessage }</ErrorMessage>
      )}

      {/* SuccessMessage */}
      {isSuccess && (
        <SuccessMessage>フィードバックを送信しました</SuccessMessage>
      )}

      <p className={ classes.message }>
        バグやご要望などをこちらのフォームよりご報告ください。
      </p>
      <form onSubmit={ handleSubmit }>
        <FormItem label='内容' required
          error={ Boolean(validationError.body) }
          helperText={ validationError.body }
        >
          <TextField name='body' variant='outlined'
            fullWidth
            multiline
            value={ formValue.body }
            onChange={ handleBodyChange }
            required
            error={ Boolean(validationError.body) }
            rows={ 10 }
          />
        </FormItem>
        <FormItem label='添付ファイル（スクリーンショットなど）'
          error={ Boolean(validationError.files) }
          helperText={ validationError.files }
        >
          {formValue.files && (
            formValue.files.map((file, index) => (
              <UploadFile key={ `${file.name}_${file.lastModified}` } file={file} onRemove={() => handleRemoveFile(index)}/>
            ))
          )}
          <label htmlFor='feedback-file' className={ classes.fileSelect }>
            <span className={ classes.fileSelectLabel }>添付ファイルを選択</span>
            <input type='file' id='feedback-file' multiple accept='image/png,image/jpeg,image/gif'
              style={{display: 'none'}}
              onChange={ handleFileChange }
            />
          </label>
          <FormHelperText>最大ファイル容量：合計25MB</FormHelperText>
          {isFileError && (<FormHelperText error>ファイルサイズが大きすぎます</FormHelperText>)}
        </FormItem>
        <div className={ classes.buttonContainer }>
          <Button type='submit' variant='contained' className={ classes.button }
            fullWidth
            disableElevation
            disabled={ disabled || isSending }
          >
            送信する{isSending && <CircularProgress className={ classes.progress } size={16} />}
          </Button>
        </div>
      </form>
    </>
  );
}

export default FeedbackPage;