import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontSize: '0.875rem',
    marginTop: 20,
    marginBottom: 20,
    padding: '15px 20px',
    borderRadius: 4,
    fontWeight: 'normal',
    color: '#dd2448',
    border: '1px solid #e69aaa',
    backgroundColor: '#fad7d0',
  }
});

interface Props {}

const ErrorMessage: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <p className={ classes.root }>{ props.children }</p>
    </>
  );
}

export default ErrorMessage;
