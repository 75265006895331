import { Epic, combineEpics } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { handleError } from '../utils/function/handleError';
import * as appActions from '../actions/AppAction';
import * as toastActions from '../actions/ToastAction';

const handleErrorEpic: Epic = action$ => action$.pipe(
  filter(appActions.handleError.match),
  map(({payload}) => toastActions.show({
    message: handleError(payload),
    type: 'error'
  }))
);

const appEpic = combineEpics(
  handleErrorEpic,
);

export default appEpic;