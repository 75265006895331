export function copyToClipboard(value: string): void {
  // Textareaを生成
  const textarea = document.createElement('textarea');
  // 生成したTextareaをReadonlyに設定
  textarea.readOnly = true;
  // Valueに参戦IDを設定
  textarea.value = value;
  // 参戦IDを選択
  textarea.selectionStart = 0;
  textarea.selectionEnd = value.length;
  // 生成したTextareaのStyleを設定
  const textareaStyle = textarea.style;
  textareaStyle.fontSize = '16px';
  textareaStyle.position = 'fixed';
  textareaStyle.top = '0';
  textareaStyle.left = '-100%';
  // TextareaをDOMに追加
  document.body.appendChild(textarea);
  // Textareaにフォーカスを当てる
  textarea.focus();
  const result = document.execCommand('copy');
  // フォーカスを解除
  textarea.blur();
  // TextareaをDOMから削除
  document.body.removeChild(textarea);
}
