import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as infoActions from '../actions/InformationAction';
import { Information } from '../utils/interface/Information';

export interface InformationState {
  isLoading: boolean;
  informations: Information[]
}

const initialState: InformationState = {
  isLoading: false,
  informations: []
};

const informationReducer = reducerWithInitialState(initialState)

// お知らせの取得 - 開始
.case(infoActions.fetchInformations.started, (state, payload) => ({
  ...state,
  isLoading: true
}))

// お知らせの取得 - 成功
.case(infoActions.fetchInformations.done, (state, payload) => ({
  ...state,
  isLoading: false,
  informations: payload.result
}))

// お知らせの取得 - 失敗
.case(infoActions.fetchInformations.failed, (state, payload) => ({
  ...state,
  isLoading: false
}))

export default informationReducer;