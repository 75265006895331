import React from 'react';
import { IconButton, IconButtonProps, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch } from 'react-redux';
import * as layoutActions from '../../actions/LayoutAction';

const useStyles = makeStyles({
  root: {
    color: '#ffffff'
  }
});

interface Props extends IconButtonProps {}

const MenuButton: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(layoutActions.openMenu());
  }

  return (
    <>
      <IconButton {...props} className={ classes.root } onClick={ handleClick }>
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default MenuButton;