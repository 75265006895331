import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Information } from '../../utils/interface/Information';

interface Props {
  information: Information;
}

const InformationCard: React.FC<Props> = (props) => {
  const classes = useStyles();

  const date = useMemo(() => {
    return moment(props.information.date).format('YYYY年MM月DD日');
  }, [props.information.date])

  return (
    <>
      <div className={ classes.root }>
        <div className={ classes.date }>{ date }</div>
        <p className={ classes.body }>{ props.information.body }</p>
      </div>
    </>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    minWidth: 0,
    boxSizing: 'border-box',
    padding: '15px 0',
  },
  date: {
    margin: 0,
    flex: '0 0 130px'
  },
  body: {
    margin: 0,
    flex: '1 1 auto'
  }
})

export default InformationCard;