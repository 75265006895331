import { RaidEntity } from '../utils/interface/entity/RaidEntity';
import { RaidCategoryEntity } from '../utils/interface/entity/RaidCategoryEntity';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as entityActions from '../actions/EntityAction';

export interface UpdateEntityPayload {
  raids?: {[key: number]: RaidEntity};
  raidCategories?: {[key: number]: RaidCategoryEntity};
}

export interface EntityState {
  raids: {[key: number]: RaidEntity};
  raidCategories: {[key: number]: RaidCategoryEntity};
}

const initialState: EntityState = {
  raids: {},
  raidCategories: {}
};

const entityReducer = reducerWithInitialState(initialState)

// エンティティを更新
.case(entityActions.update, (state, payload) => ({
  ...state,
  raids: {
    ...state.raids,
    ...payload.raids
  },
  raidCategories: {
    ...state.raidCategories,
    ...payload.raidCategories
  }
}));

export default entityReducer;