import React from 'react';
import loadImage, { LoadImageOptions } from 'blueimp-load-image';
import { base64ToBlob } from '../../../utils/function/base64ToBlob';
import { makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

declare const window: any;
const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;

const useStyles = makeStyles({
  root: {
    position: 'relative',
    zIndex: 0,
    marginBottom: 20
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  button: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    color: '#ffffff',
  }
});

interface Props {
  file: File;
  onRemove?: () => void;
}

const UploadFile: React.FC<Props> = ({file, onRemove}) => {
  const classes = useStyles();
  const [uri, setUri] = React.useState<string | null>(null);

  React.useEffect(() => {
    setUri(null);

    loadImage.parseMetaData(file, (data: any) => {
      const options: LoadImageOptions = {canvas: true};

      if (data.exif) {
        options.orientation = data.exif.get('Orientation');
      }

      return loadImage(file, (canvas) => {
        if (canvas instanceof HTMLCanvasElement) {
          const dataUri = canvas.toDataURL(file.type);
          const blob = base64ToBlob(dataUri, file.type);
          const uri = createObjectURL(blob);

          setUri(uri);
        }
      }, options)
    });
  }, [file]);

  return (
    <>
      {uri && (
        <div className={ classes.root }>
          <IconButton size='small' className={ classes.button } onClick={ onRemove }>
            <CloseIcon />
          </IconButton>
          <img src={ uri } className={ classes.img }/>
        </div>
      )}
    </>
  );
}

export default UploadFile;