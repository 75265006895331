import React from 'react';
import { makeStyles, FormLabel, FormLabelProps, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& + &': {
      marginTop: 25
    }
  },
  label: {
    display: 'inline-block',
    color: '#2d2d2d',
    fontSize: '0.875rem',
    fontWeight: 600,
    marginBottom: 10
  },
});

interface Props extends FormLabelProps {
  label?: string;
  helperText?: string;
}

const FormItem: React.FC<Props> = ({label, helperText, children, className, ...rest}) => {
  // CSS Classes
  const classes = useStyles();

  return (
    <>
      <div className={ `${classes.root} ${ className || '' }` }>
        {label && (
          <FormLabel className={ classes.label }
            {...rest}
          >{ label }</FormLabel>
        )}
        <div>{ children }</div>
        {helperText && (
          <div>
          <FormHelperText error={ rest.error }>{ helperText }</FormHelperText>
          </div>
        )}
      </div>
    </>
  );
}

export default FormItem;
